<template>
  <div>
    <div
      class="w-1/2 mx-auto text-center text-2xl bg-gray-50 py-6 px-16 rounded-xl mt-32 caret-color"
    >
      <p class="text-sm pb-8">(r) vytvořeno systémem maklerenechci.cz</p>

      <div class="pb-2">
        {{ houseTour.includes(receipt.description) ? "Stvrzenka" : "Daňový doklad" }}
      </div>
      <div class="pb-8 text-gray-500 text-lg">
        Doklad číslo #
        {{ houseTour.includes(receipt.description) ? receipt.tour_num : this.numericalSerie }}
        {{ loaded ? receipt.data.receipt_number : "..." }}
      </div>

      <div
        class="grid grid-cols-3 gap-4 mb-12 text-gray-400 text-base font-bold uppercase"
      >
        <div>SLUŽBA</div>
        <div>Datum uskut. zdan. plnění</div>
        <div>Způsob platby</div>
        <div class="text-black font-normal">
          {{ loaded ? receipt.description : "..." }}
        </div>
        <div class="text-black font-normal">
          {{ loaded ? unixTimestampConverter(receipt.data.created) : "..." }}
        </div>
        <div class="text-black font-normal">
          {{ loaded ? receipt.data.payment_method_details.card.brand : "..." }} -
          {{ loaded ? receipt.data.payment_method_details.card.last4 : "..." }}
        </div>
        <div>{{ ticket ? "" : "Cena bez DPH" }}</div>
        <div>{{ ticket ? "Typ platby" : "DPH " + this.dph * 100 }}</div>
        <div>{{ ticket ? "Cena" : "Cena s DPH" }}</div>
        <div class="text-black font-normal">
          {{
            loaded
              ? ticket
                ? ""
                : `${parseFloat(withoutDphHandler(receipt.data.amount_captured).withoutDph).toFixed(2)},- Kč`
              : "..."
          }}
        </div>
        <div class="text-black font-normal">
          {{
            loaded
              ? ticket
                ? "Záloha za službu"
                : `${parseFloat(withoutDphHandler(receipt.data.amount_captured).dph).toFixed(2)},- Kč`
              : "..."
          }}
        </div>
        <div class="text-black font-normal">
          {{
            loaded
              ? withoutDphHandler(receipt.data.amount_captured).price + ".00,- Kč"
              : "..."
          }}
        </div>
      </div>

      <!-- POKUD JE HOUSETOUR -->
      <div
        v-if="
          receipt.description === '1x Profesionální fotografie' ||
          receipt.description === '1x Profesionální video' ||
          receipt.description === '1x 3D Model'
        "
        class="flex flex-col mx-auto bg-gray-100 rounded-lg mb-4 text-left"
      >
        <div class="grid grid-cols-2">
          <div>
            <div class="flex flex-row justify-between p-4 pb-0 text-base">
              <div class="font-bold">Příjemce platby</div>
            </div>
            <div class="flex flex-col justify-between p-4 text-base text-left">
              <div>JoViRo, s.r.o.</div>
              <div>Petrohradská 1486/6, Vršovice</div>
              <div>Praha 10, 101 00</div>
              <div>IČ: 11721669</div>
              <div>DIČ: CZ11721669</div>
            </div>
          </div>
          <div>
            <div class="flex flex-row justify-between p-4 pb-0 text-base">
              <div class="font-bold">Dodavatel</div>
            </div>
            <div class="flex flex-col justify-between p-4 text-base text-left">
              <div>Mgr. Radoslav Kašparec</div>
              <div>Korunní 1208/74,</div>
              <div>Praha 10, 101 00</div>
              <div>IČ: 08154457</div>
            </div>
          </div>
        </div>
      </div>

      <!-- POKUD JE PRÁVNÍK -->
      <div
        v-if="receipt.description === '1x Konzultace s advokátem'"
        class="flex flex-col mx-auto bg-gray-100 rounded-lg mb-4 text-left"
      >
        <div class="grid grid-cols-2">
          <div>
            <div class="flex flex-row justify-between p-4 pb-0 text-base">
              <div class="font-bold">Dodavatel</div>
            </div>
            <div class="flex flex-col justify-between p-4 text-base text-left">
              <div>Mgr. Zdeněk Plesník</div>
              <div>Krakovská 1366/25, Praha 1</div>
              <div>č. ev. ČAK 15966</div>
              <div>IČO: 03535738</div>
              <div>DIČ: CZ8511255896</div>
            </div>
          </div>
        </div>
      </div>

      <!-- POKUD JE STAGING -->
      <div
        v-if="receipt.description === '1x Home staging (domácí scéna)'"
        class="flex flex-col mx-auto bg-gray-100 rounded-lg mb-4 text-left"
      >
        <div class="grid grid-cols-2">
          <div>
            <div class="flex flex-row justify-between p-4 pb-0 text-base">
              <div class="font-bold">Dodavatel</div>
            </div>
            <div class="flex flex-col justify-between p-4 text-base text-left">
              <div>Šárka Chrdlová</div>
              <div>U Zastávky 427/1,</div>
              <div>Říčany, 251 01</div>
              <div>IČ: 00919993</div>
            </div>
          </div>
        </div>
      </div>

      <!-- POKUD JE OSTATNÍ -->
      <div
        v-if="
          receipt.description !== '1x Profesionální fotografie' &&
          receipt.description !== '1x Profesionální video' &&
          receipt.description !== '1x 3D Model' &&
          receipt.description !== '1x Home staging (domácí scéna)' &&
          receipt.description !== '1x Konzultace s advokátem'
        "
        class="flex flex-col mx-auto bg-gray-100 rounded-lg mb-4 text-left"
      >
        <div class="grid grid-cols-2">
          <div>
            <div class="flex flex-row justify-between p-4 pb-0 text-base">
              <div class="font-bold">Dodavatel</div>
            </div>
            <div class="flex flex-col justify-between p-4 text-base text-left">
              <div>JoViRo, s.r.o.</div>
              <div>Petrohradská 1486/6, Vršovice</div>
              <div>Praha 10, 10 100</div>
              <div>IČ: 11721669</div>
              <div>DIČ: CZ11721669</div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col mx-auto bg-gray-100 rounded-lg">
        <div class="flex flex-row justify-between p-4 text-base">
          <div>
            {{ loaded ? receipt.description.replace("1x", "") + " 1x" : "..." }}
          </div>
          <div>
            {{
              loaded
                ? receipt.data.amount.toString().slice(0, -2) + ".00,- Kč"
                : "..."
            }}
          </div>
        </div>
        <div class="flex flex-row justify-between p-4 text-base">
          <div class="font-bold text-gray-700 uppercase">
            {{ ticket ? "Účtovaná částka" : "Účtovaná částka s DPH" }}
          </div>
          <div class="font-bold text-gray-700 uppercase">
            {{
              loaded
                ? receipt.data.amount_captured.toString().slice(0, -2) + ".00,- Kč"
                : "..."
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getInvoiceData } from "../../services/rest/services";

export default {
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("main", ["currentProperty"]),
  },

  data() {
    return {
      receipt: {},
      receipt_details: {},
      numericalSerie: null,
      loaded: false,
      houseTour: [
        "1x 3D Model",
        "1x Profesionální fotografie",
        "1x Profesionální video",
      ],
      ticket: false,
      dph: 0.21,
      price: null,
      dphPrice: null,
      withoutDphPrice: null,
    };
  },

  async mounted() {
    this.receipt = await getInvoiceData(this.$route.params.id);
    this.loaded = !this.loaded;

    if (this.receipt.staging_num) {
      this.numericalSerie = this.receipt.staging_num;
    }
    if (this.receipt.lawyer_num) {
      this.numericalSerie = this.receipt.lawyer_num;
    }
    if (this.receipt.other_num) {
      this.numericalSerie = this.receipt.other_num;
    }
  },
  methods: {
    unixTimestampConverter(data) {
      const milliseconds = data * 1000;
      const czDate = new Date(milliseconds);
      return this.$moment(czDate).format("DD. MM. YYYY");
    },
    withoutDphHandler(decimallyPrice) {
      this.price = (decimallyPrice - (decimallyPrice % 1000)) / 100;
      this.dphPrice = this.price * this.dph;
      this.withoutDphPrice = (this.dphPrice - this.price) * -1;
      return {
        price: this.price,
        dph: this.dphPrice,
        withoutDph: this.withoutDphPrice,
      };
    },
  },
};
</script>

<style scoped>
.caret-color {
  caret-color: #1b26e2 !important;
}
::selection {
  background: #1b26e2 !important;
  color: white;
}
</style>
